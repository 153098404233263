import "./src/styles/index.css";
import "./src/styles/embed-responsive.css";
import "./node_modules/prism-themes/themes/prism-base16-ateliersulphurpool.light.css";
import "react-toggle/style.css";

export const onRouteUpdate = ({ location }) => {
    if (!location.pathname.match(/(blogposts|stories)\/./)) {
        return;
    }

    let scrolling = false;
    const scrollingElement = document.querySelector("main > div");
    const progressBarElement = document.querySelector("#progress");

    scrollingElement.addEventListener("scroll", () => {
        const progress =
            (scrollingElement.scrollTop / scrollingElement.scrollHeight) * 100;

        if (!scrolling) {
            window.requestAnimationFrame(() => {
                progressBarElement.setAttribute(
                    "style",
                    `width: ${progress}%;`,
                );
                scrolling = false;
            });

            scrolling = true;
        }
    });
};
