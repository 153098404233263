// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blogposts-tsx": () => import("./../../../src/pages/blogposts.tsx" /* webpackChunkName: "component---src-pages-blogposts-tsx" */),
  "component---src-pages-illustration-tsx": () => import("./../../../src/pages/illustration.tsx" /* webpackChunkName: "component---src-pages-illustration-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-music-tsx": () => import("./../../../src/pages/music.tsx" /* webpackChunkName: "component---src-pages-music-tsx" */),
  "component---src-pages-open-source-tsx": () => import("./../../../src/pages/open-source.tsx" /* webpackChunkName: "component---src-pages-open-source-tsx" */),
  "component---src-pages-photography-tsx": () => import("./../../../src/pages/photography.tsx" /* webpackChunkName: "component---src-pages-photography-tsx" */),
  "component---src-pages-public-speaking-tsx": () => import("./../../../src/pages/public-speaking.tsx" /* webpackChunkName: "component---src-pages-public-speaking-tsx" */),
  "component---src-pages-stories-tsx": () => import("./../../../src/pages/stories.tsx" /* webpackChunkName: "component---src-pages-stories-tsx" */),
  "component---src-pages-today-is-sunday-tsx": () => import("./../../../src/pages/today-is-sunday.tsx" /* webpackChunkName: "component---src-pages-today-is-sunday-tsx" */),
  "component---src-pages-webcomic-statistics-tsx": () => import("./../../../src/pages/webcomic/statistics.tsx" /* webpackChunkName: "component---src-pages-webcomic-statistics-tsx" */),
  "component---src-templates-markdown-file-tsx": () => import("./../../../src/templates/MarkdownFile.tsx" /* webpackChunkName: "component---src-templates-markdown-file-tsx" */),
  "component---src-templates-photoset-tsx": () => import("./../../../src/templates/Photoset.tsx" /* webpackChunkName: "component---src-templates-photoset-tsx" */),
  "component---src-templates-webcomic-pane-tsx": () => import("./../../../src/templates/WebcomicPane.tsx" /* webpackChunkName: "component---src-templates-webcomic-pane-tsx" */)
}

